<template>
  <div>
    <v-app-bar
      color="primary"
      dense
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-toolbar-title> Update Bank Details </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card wrap class="card1">
      <v-form
        ref="form2"
        v-model="valid1"
        lazy-validation
        v-on:submit.prevent="BankDetailsUpdate"
      >
        <v-card-text>
          <v-row wrap>
            <v-col cols="12" md="12">
              <h6>
                <span class="text-danger">*</span> indicates required field
              </h6>
            </v-col>
            <v-col cols="12" sm="5" lg="5" md="5">
              <h6><span class="text-danger">*</span> IFSC Code</h6>
              <v-text-field
                v-model="IfscCode"
                :rules="IfscCodeRules"
                placeholder="IFSC Code"
                required
                outlined
                dense
              ></v-text-field>
              <v-btn
                :disabled="!valid1"
                @click.prevent="searchForm"
                :loading="SearchFlag"
                color="#8950FC"
                class="white--text text-h6 mb-3"
                rounded
              >
                Search
              </v-btn>
            </v-col>
            <v-col v-if="FormFlag" cols="12" sm="7" lg="7" md="7">
              <v-card class="py-4" color="#f7cad0">
                <v-row wrap class="mx-2">
                  <v-col cols="12" md="12" class="text-h6 bold--text">
                    Bank Name :
                    <span class="blue--text"> {{ BankName }}</span>
                  </v-col>
                </v-row>
                <v-row wrap class="mx-2">
                  <v-col cols="12" md="12" class="text-h6 bold--text">
                    Branch Name :
                    <span class="blue--text"> {{ BranchName }}</span></v-col
                  >
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" lg="12" md="12">
              <template>
                <v-skeleton-loader
                  v-if="SearchFlag"
                  class="mx-auto"
                  elevation="12"
                  max-width="400"
                  type="card-avatar,list-item-two-line"
                ></v-skeleton-loader> </template></v-col
          ></v-row>
          <v-row v-if="!FormFlag"> <br /><br /> </v-row>
          <v-row wrap v-if="FormFlag">
            <v-col cols="12" sm="6" lg="4" md="4">
              <h6><span class="text-danger">*</span> Account Name</h6>
              <v-text-field
                v-model="Name"
                :rules="NameRules"
                placeholder="Account  Name"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="4">
              <h6><span class="text-danger">*</span> Account Number</h6>
              <v-text-field
                v-model="AccountNumber"
                :rules="AccountNumberRules"
                :counter="15"
                :type="show3 ? 'text' : 'password'"
                placeholder="Bank Account Number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="4">
              <h6><span class="text-danger">*</span>Re-Enter Account Number</h6>
              <v-text-field
                v-model="ReEnterAccountNumber"
                :rules="ReEnterAccountNumberRules"
                :counter="15"
                placeholder="Re-Enter Account Number"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5" lg="5" sm="5">
              <h6 class="mycard-text1 fsize-4">
                <span class="text-danger">* </span> Upload Bank Account Document
                <br />
                <small>(only jpg, png ,jpeg image files are accepted)</small>
              </h6>
              <v-file-input
                :clearable="false"
                truncate-length="25"
                accept="image/png, image/jpeg"
                placeholder="Upload Event Photo"
                prepend-icon="mdi-image"
                append-outer-icon="mdi-delete"
                v-model="UploadedImage"
                :rules="ImageRules"
                @change="addImage(1)"
                @click="checkoutButton()"
              >
                <template v-slot:append-outer>
                  <v-icon color="red" @click="deleteImage(1)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-file-input>
            </v-col>
            <v-col align="center" cols="12" sm="7" lg="7" md="7">
              <h6 class="mycard-text1 fsize-4">Image Edit Options</h6>
              <v-btn-toggle>
                <v-btn fab small @click.prevent="flip(true, false)">
                  <v-icon>mdi-swap-horizontal-bold</v-icon>
                </v-btn>
                <v-btn fab small @click.prevent="flip(false, true)">
                  <v-icon>mdi-swap-vertical-bold</v-icon>
                </v-btn>
                <v-btn fab small @click.prevent="rotate(90)">
                  <v-icon>mdi-rotate-right-variant</v-icon>
                </v-btn>
                <v-btn fab small @click.prevent="rotate(-90)">
                  <v-icon>mdi-rotate-left-variant</v-icon>
                </v-btn>
              </v-btn-toggle>

              <!-- </v-col>
              <v-col align="center" cols="12" md="4"> -->
              <h6 class="mycard-text1 fsize-4 mt-4">
                Uploaded Document will Appear Here
              </h6>

              <cropper ref="cropper" :src="SelectedImage.ImageUrl" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="6" align="right">
              <v-btn
                :disabled="!valid1"
                @click.prevent="confirmAlert()"
                :loading="SubmitFlag"
                color="#1db954"
                class="black--text text-h6"
              >
                Submit
              </v-btn>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-btn
                @click="closePrompt"
                color="#f94144"
                class="black--text text-h6"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <br />
      </v-form>
    </v-card>
  </div>
</template>
  
<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      show3: false,
      SearchFlag: false,

      FormFlag: false,
      NameRules: [(v) => !!v || "Name is required"],
      Name: "",
      AccountNumber: "",
      AccountNumber: "",
      ReEnterAccountNumber: "",

      AccountNumberRules: [
        (v) => !!v || "Account Number is required",
        (v) => v.length <= 15 || "Account Number must be 15 characters",
      ],

      ReEnterAccountNumberRules: [
        (v) => !!v || "Re-Enter Account Number is required",
        (v) => v <= this.AccountNumber || "Not match.",
      ],
      IfscCodeRules: [(v) => !!v || "IFSC Code is required"],
      IfscCode: "",

      ImageRules: [(v) => !!v || "Image is required"],
      SelectedImage: {},
      UploadedImage: {},

      BankName: "",
      BranchName: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      CurrentYearId: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },

    IfscCode: function () {
      console.log("watch IfscCode");
      this.FormFlag = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_lom",
        Action: "edit_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadedImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage.src) {
            URL.revokeObjectURL(this.SelectedImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage = {
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.UploadedImage = {};
          break;

        default:
          break;
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideupdatePrompt");
    },
    searchForm() {
      console.log("searchForm called");
      this.SearchFlag = true;
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/get-bank-details";
      var upload = {
        IfscCode: this.IfscCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          thisIns.SearchFlag = false;
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.SubmitFlag = false;

          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          if (flag == 1) {
            thisIns.BankName = records.BankName;
            thisIns.BranchName = records.BranchName;
            console.log("BankName" + thisIns.BankName);
            console.log("BranchName" + thisIns.BranchName);

            thisIns.FormFlag = true;
            console.log("FormFlag" + thisIns.FormFlag);
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
        });
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        var htmlTxt = "";
        // htmlTxt += "<b>Please verify all the information before proceeding</b>";
        htmlTxt += "<b>Bank Account details</b>";
        htmlTxt += "<br/><br/>";
        htmlTxt += "Account  Name : <b>" + this.Name + "</b><br/>";
        htmlTxt += "Bank Name : <b>" + this.BankName + "</b><br/>";
        htmlTxt += "Branch Name : <b>" + this.BranchName + "</b><br/>";
        htmlTxt += "Account Number : <b>" + this.AccountNumber + "</b><br/>";
        htmlTxt += "IFSC Number : <b> " + this.IfscCode + "</b><br/>";
        htmlTxt +=
          "<br/><img src='" +
          this.SelectedImage.ImageUrl +
          "' width=80 height=80/></b><br/>";
        htmlTxt += "<br/><br/>";
        htmlTxt += "Do you want to continue?";
        Swal.fire({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            this.uploadImage();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
    uploadImage() {
      console.log("uploadImage called");
      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        const upload = new FormData();
        upload.append("UserInterface", 2);
        upload.append("Name", this.Name);
        upload.append("AccountNumber", this.AccountNumber);
        upload.append("ReEnterAccountNumber", this.ReEnterAccountNumber);
        upload.append("IfscCode", this.IfscCode);

        var UploadedImageName = this.UploadedImage.name;
        UploadedImageName =
          UploadedImageName == (null || undefined) ? "" : UploadedImageName;
        if (UploadedImageName != "") {
          const { canvas } = this.$refs.cropper.getResult();
          if (canvas) {
            canvas.toBlob((blob) => {
              upload.append("Image", blob, UploadedImageName);
              this.UploadFlag = true;
              this.submitForm(upload);
            }, "image/jpeg");
          } else {
            this.submitForm(upload);
          }
        } else {
          this.submitForm(upload);
        }
      } else {
        var message = "";
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        if (MemberId == 0) {
          message += "Kindly select member. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");
      var UploadFlag = this.UploadFlag;
      console.log("UploadFlag=" + UploadFlag);

      if (UploadFlag) {
        this.SubmitFlag = true;
        this.ResultFlag = false;
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-bank-details/store";

        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        console.log("upload=" + JSON.stringify(upload));
        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;
          });
      } else {
        var message = "";
        if (!UploadFlag) {
          message += "File is not selected";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style scoped>
</style>
