<template>
  <div id="card">
    <v-row>
      <v-col cols="12" lg="8" md="4" class="mb-4">
        <span class="text-h6 bold black--text dark">Status : </span>
        <v-chip
          draggable
          dark
          class="text-h6 px-5 py-5 ml-4"
          :color="getActiveStatusColor(VotingRightsStatus)"
          text-color="white"
        >
          {{ VotingRightsStatus }}
        </v-chip>
        <br />
        <div v-if="VotingRightsStatus != 'Not completed'">
          <br />
          <span class="text-h6 bold black--text dark">Document : </span>
          <a :href="DocumentPath" target="_blank" class="text-h5 ml-4"
            >Download</a
          >
        </div>
        <div v-else><br /><br /></div>
        <br />
        <div align="center">
          <v-btn
            rounded
            color="#000"
            class="font-size-h6 white--text"
            @click.prevent="uploadDocumentPrompt = true"
            v-if="VotingRightsStatus != 'Approved'"
          >
            Upload
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="uploadDocumentPrompt" persistent max-width="70%">
      <voting-rights-create
        :addRecordPrompt="uploadDocumentPrompt"
        @hideAddRecordPrompt="hideUploadRecordPrompt"
        v-if="uploadDocumentPrompt"
      ></voting-rights-create>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import VotingRightsCreate from "../voting-rights/VotingRightsCreate.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
export default {
  mixins: [common],
  components: {
    VotingRightsCreate,
  },
  data() {
    return {
      VotingRightsStatus: "",
      DocumentPath: "",
      uploadDocumentPrompt: false,
      EditRecordPrompt: false,

      EligibleCount: 0,
      selectedData: {},
      VotingData: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = NextJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var server_url = companyConfig.apiURL;
        console.log("server_url = " + server_url);
        var SampleFilePath = server_url + "assets/sample/voting_rights.pdf";
        console.log("SampleFilePath = " + SampleFilePath);
        this.SampleFilePath = SampleFilePath;
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "voting_rights",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.pageData();
    },
    pageData() {
      console.log("pageData called");
      this.getTableRecords();
    },
    hideUploadRecordPrompt() {
      console.log("hideUploadRecordPrompt called");
      this.uploadDocumentPrompt = false;
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom-document/show";
      var upload = {
        UserInterface: 3,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          if (flag) {
            var records = response.data.records;
            console.log("records=" + JSON.stringify(records));

            thisIns.VotingRightsStatus = records.LomDocumentStatusTxt;
            thisIns.DocumentPath = records.DocumentAssetPath;

            // thisIns.toast("success", output);
          } else {
            thisIns.VotingRightsStatus = "Not completed";
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
  },
  mounted() {},
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>