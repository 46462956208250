<template>
  <div>
    <v-app-bar color="primary" dense dark>
      <v-toolbar-title> Update Location Details </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card wrap>
      <v-form
        ref="form1"
        v-model="valid1"
        lazy-validation
        v-on:submit.prevent="LocationDetailsUpdate"
      >
        <v-card-text>
          <v-row wrap>
            <v-col cols="12" md="12">
              <h6>
                <span class="text-danger mt-0">*</span> indicates required field
              </h6>
            </v-col>
            <v-col cols="12" sm="12" lg="4" md="4" class="mt-0 mb-0">
              <h6><span class="text-danger">*</span> State</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="StateCodeOptionsLoading"
                :items="StateCodeOptions"
                :rules="StateCodeRules"
                v-model="StateCode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" lg="4" md="4" class="mt-0 mb-0">
              <h6><span class="text-danger">*</span> District</h6>

              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="DistrictCodeOptionsLoading"
                :items="DistrictCodeOptions"
                :rules="DistrictCodeRules"
                v-model="DistrictCode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" lg="4" md="4">
              <h6><span class="text-danger">*</span> City</h6>

              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="CityCodeOptionsLoading"
                :items="CityCodeOptions"
                :rules="CityCodeRules"
                v-model="CityCode"
                dense
                clearable
                required
                outlined
                base-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="6" align="right">
              <v-btn
                :disabled="!valid1"
                @click.prevent="LocationDetailsUpdate"
                :loading="SubmitFlag"
                color="#1db954"
                class="black--text text-h6 mb-6"
              >
                Submit
              </v-btn>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-btn
                @click="closePrompt"
                color="#f94144"
                class="black--text text-h6"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      CurrentYearId: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
        this.StateCodeOptionsLoading = true;
        this.getStateCodeOptions("StateCode", "StateCodeOptions");
      }
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.StateCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode");
      this.DistrictCodeOptionsLoading = true;
      this.getDistrictCodeOptions(
        "DistrictCode",
        "DistrictCodeOptions",
        this.StateCode
      );
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.DistrictCodeOptionsLoading = false;
    },
    DistrictCode: function () {
      console.log("watch DistrictCode");
      this.CityCodeOptionsLoading = true;
      this.getCityCodeOptions("CityCode", "CityCodeOptions", this.DistrictCode);
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.CityCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_lom",
        Action: "edit_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideupdatePrompt");
    },
    getStateCodeOptions(source, destination) {
      console.log("getStateCodeOptions called");
      if (source != "" && destination != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictCodeOptions(source, destination, StateCode) {
      console.log("getDistrictCodeOptions called StateCode=" + StateCode);
      if (source != "" && destination != "" && StateCode != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions(source, destination, DistrictCode) {
      console.log("getCityCodeOptions called DistrictCode=" + DistrictCode);
      if (source != "" && destination != "" && DistrictCode != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    LocationDetailsUpdate() {
      console.log("LocationDetailsUpdate is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var upload = {
          UserInterface: 2,
          City: this.CityCode,
        };
        console.log({ upload });

        this.progessStart = 1;
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
 <style scoped>
.card2 {
  border-radius: 0 20px 0 20px;
  height: 550px;
}
</style>