<template>
  <div>
    <div v-if="Records.CityId != ''">
      <v-card-text class="text-h6 black--text ml-1">
        <v-row wrap>
          <v-col cols="12" lg="12" md="12" sm="12">
            State &nbsp;
            <span class="ml-9">: &nbsp; {{ Records.StateName }}</span>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col cols="12" lg="12" md="12" sm="12">
            District &nbsp;
            <span class="ml-6">: &nbsp; {{ Records.DistrictName }}</span>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col cols="12" lg="12" md="12" sm="12">
            City &nbsp;
            <span class="ml-12">: &nbsp; {{ Records.CityName }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <div v-else>
      <v-row align="center mt-14 mb-4 px-20">
        <v-spacer></v-spacer>
        <v-col cols="12" md="12" lg="12" sm="12" align="center">
          <v-alert elevation="1" prominent shaped color="warning">
            <span class="text-h5 black--text">
              <v-icon medium color="black"> fa-info-circle </v-icon>

              Not Updated
            </span>
          </v-alert>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>

    <div align="center">
      <v-btn
        color="black"
        class="white--text pt-2"
        rounded
        @click.prevent="updatePrompt = true"
        ><h6>Update</h6></v-btn
      >
    </div>
    <v-dialog v-model="updatePrompt" persistent max-width="70%">
      <location-information-update
        @hideupdatePrompt="hideupdatePrompt"
        v-if="updatePrompt"
      ></location-information-update>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import LocationInformationUpdate from "./LocationInformationUpdate.vue";

export default {
  mixins: [common],
  components: {
    LocationInformationUpdate,
  },
  data() {
    return {
      Records: {},
      updatePrompt: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      CurrentYearId: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_lom",
        Action: "list_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getLocationRecords();
    },
    hideupdatePrompt() {
      console.log("hideupdatePrompt called");
      this.updatePrompt = false;
      this.getLocationRecords();
    },
    getLocationRecords() {
      console.log("getLocationRecords is called");

      this.SearchFlag = true;
      this.rows = [];
      this.zone = {};
      this.lom = {};

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom/show";
      var upload = {
        UserInterface: 2,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.Records = response.data.records;
            console.log("Records=" + JSON.stringify(thisIns.Records));
            // thisIns.toast("success", output);
          } else {
            // thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style>
</style>
