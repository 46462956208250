<template>
  <div>
    <div v-if="ResultData.length > 0">
      <div
        class="black--text"
        v-for="item in ResultData"
        :key="item.LomBankAccountId"
      >
        <v-row wrap class="ml-2">
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-row wrap>
              <v-col cols="12" lg="12" md="12" sm="12" class="text-h6">
                Account Name &nbsp;
                <span class="ml-5">: &nbsp; {{ item.LomBankAccountName }}</span>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" lg="12" md="12" sm="12" class="text-h6">
                Account Number &nbsp;
                <span class="ml-1"
                  >: &nbsp; {{ item.LomBankAccountNumber }}</span
                >
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" lg="12" md="12" sm="12" class="text-h6">
                Bank Name &nbsp;
                <span class="ml-10">: &nbsp; {{ item.BankName }}</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="6" md="6" sm="12">
            <v-row wrap>
              <v-col cols="12" lg="12" md="12" sm="12" class="text-h6">
                IFSC code &nbsp;
                <span class="ml-13"
                  >: &nbsp; {{ item.LomBankAccountIfscCode }}</span
                >
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" lg="12" md="12" sm="12" class="text-h6">
                Branch Name &nbsp;
                <span class="ml-7">: &nbsp; {{ item.BranchName }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <v-row align="center mt-14 mb-4 ml-25 mr-25 px-30">
        <v-spacer></v-spacer>
        <v-col cols="12" md="12" lg="12" sm="12" align="center">
          <v-alert elevation="1" prominent shaped color="warning">
            <span class="text-h5 black--text">
              <v-icon medium color="black" class="mb-1">
                fa-info-circle
              </v-icon>

              Not Updated
            </span>
          </v-alert>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <br />
    <div align="center">
      <v-btn
        color="black"
        class="white--text pt-2"
        @click.prevent="updatePrompt = true"
        rounded
        ><h6>Update</h6></v-btn
      >
    </div>
    <v-dialog v-model="updatePrompt" persistent max-width="70%">
      <bank-information-update
        @hideupdatePrompt="hideupdatePrompt"
        v-if="updatePrompt"
      ></bank-information-update>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import BankInformationUpdate from "./BankInformationUpdate.vue";

export default {
  mixins: [common],
  components: {
    BankInformationUpdate,
  },
  data() {
    return {
      ResultData: [],
      LomId: "",
      updatePrompt: false,
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      CurrentYearId: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomId = LomId;

        this.pageData();
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_lom",
        Action: "list_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getBankDetails();
    },
    hideupdatePrompt() {
      console.log("hideupdatePrompt called");
      this.updatePrompt = false;
      this.getBankDetails();
    },
    getBankDetails() {
      console.log("getBankDetails called");
      this.LoadingFlag = false;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom-bank-details/lists";
      var upload = {
        UserInterface: 1,
        Lom: this.LomId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          output = response.data.output;
          flag = response.data.flag;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          console.log({ records });

          if (flag == 1) {
            // thisIns.toast("success", output);
            thisIns.ResultData = records.TableData;

            console.log("ResultData=" + JSON.stringify(thisIns.ResultData));
          } else {
            // thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style scoped>
.vertical {
  border-left: 1px solid black;
  height: 140px;
  position: absolute;
  left: 50%;
}
</style>